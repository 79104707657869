:root {
  --azul-gac: #003893;
  --amarillo-gac: #ffcf04;
  --rojo-gac: #ed0a26;
  --gris-gac: #666;
  --oscuro: #252525;
  --fondo-defecto: #fff;
}

a{
  text-decoration: none;
  color: var(--oscuro);
  font-weight: 400;
}
a:hover{
  color: #252525;
}
p{
  margin: 6px;
}

.col-lg-3 a{
  color: var(--oscuro);
}
.col-lg-6 a{
  color: var(--oscuro);
}
.rating {
  /* Center the content */
  color: #eab308;
  content: '★';
  align-items: center;
  display: flex;
  justify-content: center;

  flex-direction: row-reverse;
}

.rating__star:hover,
.rating__star:hover ~ .rating__star {
  color: transparent;
}

/*
Make all previous stars selected when hover on a star
Note that we use \`flex-direction: row-reverse\` on the container
*/
.rating__star:before,
.rating__star ~ .rating__star:before {
  color: #eab308;
  content: '★';
  left: 0;
  position: absolute;
}

.rating__star {
  font-size: 1.5rem;

  /* Reset styles for button */
  background-color: transparent;
  border: transparent;
  margin: 0 2px;
  padding: 0;

  /* Used to position the hover state */
  position: relative;
}
.text-dark {
  color: #252525;
  font-weight: 500;
}
.bg-degrade{
  background-color: initial;
  min-height: 80vh;
  height: 500px;
}
.msg-whatsApp{
  background-color: var(--fondo-defecto);
  /* height: 100px; */
  /* width: 100px; */
  opacity: 0;
  border-radius: 5px;
  margin-top: -70px;
  background-color: #47cc57af;
  padding: 3px;
  border: 1px dashed var(--price-tag-background);
  color: var(--oscuro);
}
.btn-WhatsApp:hover .msg-whatsApp{
  opacity: 1;
  position: absolute;
}
.btn-WhatsApp{
  background-color: transparent;
  border: none;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  position: fixed;
  z-index: 1038;
  top: 90%;
  left: 90%;
  background-image: url("../../public/images/img-WhatsApp.png");
  background-size: contain;
  /* filter: drop-shadow(2px 2px 2px #3ec24f66); */
}
.btn-WhatsApp img{
  background-image: url("../../public/images/img-WhatsApp.png");
  background-size: cover;
  object-fit: cover;
  width: 100%;
}

.card-carousel{
  border-radius: 20px;
  width: 98%;
  padding: 6px;
}
.bg-azul{
  background-color: var(--azul-gac);
  color: var(--fondo-defecto);
  width: 100%;
}

.Mycard{
  animation: fadeIn .5s;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  background-color: hsla(0,0%,100%,.8);
  border: none;
  border-radius: 2rem;
  box-shadow: 3px 3px 25px #66666629;
  min-height: 450px;
  padding: 3em 2em;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: 380px;
}
.bg-login{
  background-image: url("../../public/images/background.jpg");
  height: 1080px;
  background-size: contain;
}
.lines {
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  margin: auto;
  width: 100%;
  z-index: -1;
}
.lines .line {
  position: absolute;
  width: 1px;
  height: 100%;
  top: 0;
  left: 50%;
  background: rgba(255, 255, 255, 0.1);
  overflow: hidden;
}
.lines .line::after {
  content: "";
  display: block;
  position: absolute;
  height: 15vh;
  width: 100%;
  top: -50%;
  left: 0;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, #ffffff 75%, #ffffff 100%);
  animation: run 5s 0s infinite;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0.4, 0.26, 0, 0.97);
}
.lines .line:nth-child(1) {
  margin-left: -25%;
}
.lines .line:nth-child(1)::after {
  animation-delay: 2s;
}
.lines .line:nth-child(3) {
  margin-left: 25%;
}
.lines .line:nth-child(3)::after {
  animation-delay: 2.5s;
}

@keyframes run {
  0% {
    top: -50%;
  }
  100% {
    top: 110%;
  }
}

.animation-wrap{
	float: left;
	display: inline;
}
.credits-text{
	margin-top: 13px;
	margin-right: 13px;
	float: left;
	display: inline;
	font-size: 12px;
	font-family:sans-serif;
	font-weight: 100;
}
.gac{
	width: 160px;
	height: 160px;
	position: relative;
	-webkit-transform-style: preserve-3d;
	transform-style: preserve-3d;
	-webkit-transition: .4s ease;
	transition: .4s ease;
}
.adelante{
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
}
.atras img{
	width: 160px;
	height: 160px;
}
.atras{
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
}
.adelante{
	width: 100%;
}
.atras{
	-webkit-transform: rotateY(180deg);
	transform: rotateY(180deg);
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	text-align: center;
	color: #fff;
	font-family: "open sans";
}
.animation-wrap:hover .gac{
	-webkit-transform: rotateY(180deg);
	transform: rotateY(180deg);
}
.gac2{
	background-image: url('/public/images/Logo-white.png');
	background-size: cover;
}

.logo{
  width: 65%;
  max-width: 75%;
}

.menu-lateral{
  background-color: var(--fondo-defecto);
  /* box-shadow: 15px 0px 20px #efede9; */
  border-radius: 10px;
  height: 100%;
  max-width: 14%;
  min-height: 500px;
  padding: 10px 5px;
  z-index: 1;
}
.card-info{
  width: 100%;
  border-radius: 2rem;
  height: max-content;
  background-color: var(--fondo-defecto);
  box-shadow: 0px 0px 20px var(--fondo-defecto);
}
.card-user{
  width: 100%;
  height: 100%;
  background-color: #fff;
  border-radius: 15px;
  /* border-bottom: 5px solid var(--amarillo-gac); */
  padding: 4px;
  color: #252525;
  /* box-shadow: 10px 10px 50px rgb(207, 205, 205); */
  /* margin: 1rem; */
}

.img-card{
  max-height: 120px;
  border-radius: 50%;
  padding: 3px;
  border: 2px dashed var(--amarillo-gac);
}
.img-login{
  border-radius: 50%;
  /* border: 2px dashed  var(--azul-gac); */
  background: var(--amarillo-gac);
  width: 120px;
  height: 120px;
  padding: 5px;
}

.img-afiliado{
  position: absolute;
  top: -51px;
  left: 30%;
  max-height: 100px;
  border-radius: 50%;
}

.btn-menu{
  width: 100%;
  height: 47px;
  background-color: #fff;
  border-radius: 5px;
  border: none;
  margin: 2px 0px;
  font-weight: 400;
  text-align: left;
  padding: 10px 0px 10px 8px;
  color: #252525;
}
.btn-menu img{
  margin-right: 1rem;
}
.btn-menu-active{
  background-color: rgba(0, 56, 147, 0.1);
  border-radius: 5px;
  border: none;
  color: var(--azul-gac);
  font-weight: 400;
  height: 47px;
  margin: 2px 0px;
  width: 100%;
  padding: 10px 0px 10px 8px;
  text-align: left;
  letter-spacing: 1px;
  border-left: 3px solid var(--azul-gac);
}
.btn-menu-active img{
  margin-right: 1rem;
}
.btn-menu:hover{
  font-weight: 500;
  background-color: #3997ee61;
  color: #252525;
}
.submenu{
  animation:scale-up-center .58s;
  /* color: var(--azul-gac); */
  font-weight: 400;
  display: flex;
  align-items: flex-end;
  /* justify-content: center; */
  /* box-shadow: 0px 0px 1px var(--azul-gac); */

}
.submenu img{
  height: 100%;
}
.input_container-login {
  width: 100%;
  height: fit-content;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.input_label-login {
  font-size: 0.85rem;
  color: var(--oscuro);
  font-weight: 600;
}

.input_field-login {
  width: auto;
  height: 40px;
  padding: 0 0 0 40px;
  border-radius: 7px;
  outline: none;
  border: 1px solid #e5e5e5;
  filter: drop-shadow(0px 1px 0px #efefef)
    drop-shadow(0px 1px 0.5px rgba(239, 239, 239, 0.5));
  transition: all 0.3s cubic-bezier(0.15, 0.83, 0.66, 1);
}

.input_field-login:focus {
  border: 1px solid transparent;
  box-shadow: 0px 0px 0px 2px #242424;
}
.icon-login {
  width: 20px;
  position: absolute;
  z-index: 99;
  left: 12px;
  bottom: 9px;
}
.icon_password {
  width: 20px;
  position: absolute;
  z-index: 99;
  right: 12px;
  bottom: 9px;
  cursor: pointer;
}
.icon {
  width: 20px;
  position: absolute;
  z-index: 99;
  left: 12px;
  bottom: 9px;
}
@keyframes scale-up-center{ 
  0%{transform:scale(.5)} 
  100%{transform:scale(1)} 
}
.center-img{
  display: flex;
  margin: auto;
}
.btn-login{
  background-color: var(--amarillo-gac);
  border: none;
  height: 37px;
  border-radius: 5px;
  color: var(--oscuro);
  width: 100%;
  font-weight: 500;
}
.btn-login:disabled{
  /* cursor: not-allowed; */
  background-color: var(--gris-gac);
  color: var(--fondo-defecto);
}
.btn-login:hover{
  background-color: var(--azul-gac);
  color: var(--fondo-defecto);
}
.text-italic{
  font-style: italic;
}

.banner{
  background-image: url("../../public/images/banner.webp");
  border-radius: 10px;
  background-position: center right;
  background-size: cover;
  color: #fff;
  height: 120px;
  width: 100%;
  padding: 30px;
  text-shadow: 0px 3px 15px #00000073;
}

.cardHotel{
  box-shadow: 0px 0px 40px #e9e6e6;
  width: 300px;
  height: 209px;
  padding: 20px;
  border-radius: 20px;
  margin: 14px 4px;
  /* border: none; */
  position: relative;
  color: #252525;
  transition: all .2s;
}

.cardHotel:hover .btn-reservar{
  background-color: #ffcf04;
  color: #252525;
  font-weight: 500;
}

.imgHotel{
  width: 100%;
  margin: auto;
}

.btn-reservar{
  width: 100%;
  height: 47px;
  border-radius: 10px;
  background-color: #003893;
  border: none;
  font-weight: 400;
  color: white;
}

.badge{
  position: absolute;
  display: block;
  width: 150px;
  height: 150px;
  padding: 15px 0;
  background-color: #003893;
  box-shadow: 0 5px 10px rgb(0 0 0 / 10%);
  color: #fff;
  font: 700 18px/1 'Lato', sans-serif;
  text-shadow: 0 1px 1px rgb(0 0 0 / 20%);
  text-transform: uppercase;
  text-align: center;
}

.ribbon-top-left{
  top: -10px;
  left: -10px;
}

.ribbon {
  width: 150px;
  height: 150px;
  overflow: hidden;
  position: absolute;
}

.ribbon span {
  position: absolute;
  display: block;
  width: 225px;
  padding: 15px 0;
  background-color: #003893;
  box-shadow: 0 5px 10px rgb(0 0 0 / 10%);
  color: #fff;
  font: 700 18px/1 'Lato', sans-serif;
  text-shadow: 0 1px 1px rgb(0 0 0 / 20%);
  text-transform: uppercase;
  text-align: center;
}

.card-afiliado {
  background: #fff;
  border-radius: 4px;
  box-shadow: 0px 14px 40px rgb(207, 205, 205);
  max-width: 500px;
  display: flex;
  flex-direction: row;
  border-radius: 25px;
  position: relative;
  margin: 1rem;
  height: 345px;
}

.card-afiliado h2 {
  margin: 0;
  padding: 0 1rem;
}
.card-afiliado .title {
  padding: 1rem;
  text-align: right;
  color: green;
  font-weight: bold;
  font-size: 12px;
}
.card-afiliado .desc {
  padding: 0.5rem 1rem;
  font-size: 13px;
  text-align: justify;
}
.card-afiliado .actions {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
  padding: 0.5rem 1rem;
}
.card-afiliado svg {
  width: 85px;
  height: 85px;
  margin: 0 auto;
}

.img-avatar {
  width: 90px;
  height: 90px;
  position: absolute;
  border: 6px solid white;
  background-image: url("../../public/images/Logo-white.png");
  background-size: cover;
  border: none;
  top: 40px;
  left: 25px;
}

.card-afiliado-text {
  display: grid;
  grid-template-columns: 1fr 2fr;
}

.title-total {
  padding: 40px 16px;
}

.img-portada {
  width: 100%;
}

.portada {
  width: 100%;
  height: 100%;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  background-image: url("../../public/images/banner.png");
  background-position: bottom center;
  background-size: cover;
  margin: 0px 0px 0px -12px;
}

.centrar{
  display: flex;
  justify-content: center;
  align-items: center;
}
.example__ribbon--tr {
  top: -0.5rem;
  right: -0.5rem;
}
.example__ribbon {
  height: 8rem;
  width: 8rem;
  overflow: hidden;
  position: absolute;
  z-index: 1;
}
.example__ribbon--tr::before {
  top: 0;
  left: 0;
}

.example__ribbon::before{
  border: .25rem solid #003893;
  content: "";
  position: absolute;
  z-index: -1;
}
.example__ribbon--tr::after {
  bottom: 0;
  right: 0;
}
.example__ribbon::after {
  border: .25rem solid #003893;
  content: "";
  position: absolute;
  z-index: -1;
}

.example__ribbon--tr .example__title {
  transform: translate(-13px, 30px) rotate(45deg);
}
.example__title {
  background-color: #003893;
  color: #fff;
  position: absolute;
  padding: .5rem 0;
  text-transform: uppercase;
  text-align: center;
  width: 181px;
}

.example2__ribbon--tr {
  top: -0.5rem;
  right: -0.5rem;
}
.example2__ribbon {
  height: 8rem;
  width: 8rem;
  overflow: hidden;
  position: absolute;
  z-index: 1;
}
.example2__ribbon--tr::before {
  top: 0;
  left: 0;
}

.example2__ribbon::before{
  border: .25rem solid #e30b23d4;
  content: "";
  position: absolute;
  z-index: -1;
}
.example2__ribbon--tr::after {
  bottom: 0;
  right: 0;
}
.example2__ribbon::after {
  border: .25rem solid #e30b23d4;
  content: "";
  position: absolute;
  z-index: -1;
}

.example2__ribbon--tr .example2__title {
  transform: translate(-13px, 30px) rotate(45deg);
}
.example2__title {
  background-color:#e30b23d4;
  color: white;
  position: absolute;
  padding: .5rem 0;
  text-transform: uppercase;
  text-align: center;
  width: 181px;
  font-weight: 600;
}
.text-title{
  color: var(--azul-gac);
  font-weight: 600;
}

.class-logo-white{
  width: 250px;
  background-size: cover;
}

.btn-notFound{
  background-color: #162b32;
  border: none;
  border-radius: 6px;
  color: var(--fondo-defecto);
  width: 100%;
  height: 47px;
  padding: 10px;
}
.btn-notFound:hover{
  background-color: #ff4838;
}

.btn-publicidad{
  font-size: 18px;
  color: var(--oscuro);
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 2px;
  padding: 12px 0 10px;
  background: var(--fondo-defecto);
  display: block;
  border-radius: 8px;
  width: 100%;
}
.button {
  position: relative;
  display: flex;
  margin: auto;
  cursor: pointer;
  outline: none;
  border: 0;
  vertical-align: middle;
  text-decoration: none;
  background: transparent;
  padding: 0;
  font-size: inherit;
  font-family: inherit;
}
.button:disabled{
  opacity: .0;
}
.button.learn-more {
  width: 12rem;
  height: auto;
}

.button.learn-more .circle {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  position: relative;
  display: block;
  margin: 0;
  width: 3rem;
  height: 3rem;
  background: var(--azul-gac);
  border-radius: 1.625rem;
}

.button.learn-more .circle .icon {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  background: #fff;
}

.button.learn-more .circle .icon.arrow {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  left: 0.625rem;
  width: 1.125rem;
  height: 0.125rem;
  background: none;
}

.button.learn-more .circle .icon.arrow::before {
  position: absolute;
  content: "";
  top: -0.29rem;
  right: 0.0625rem;
  width: 0.625rem;
  height: 0.625rem;
  border-top: 0.125rem solid #fff;
  border-right: 0.125rem solid #fff;
  transform: rotate(45deg);
}

.button.learn-more .button-text {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 0.75rem 0;
  margin: 0 0 0 1.85rem;
  color: var(--azul-gac);
  font-weight: 700;
  line-height: 1.6;
  text-align: center;
  text-transform: uppercase;
}

.button:hover .circle {
  width: 100%;
}

.button:hover .circle .icon.arrow {
  background: #fff;
  transform: translate(1rem, 0);
}

.button:hover .button-text {
  color: #fff;
}

.button-back {
  position: relative;
  display: flex;
  margin: auto;
  cursor: pointer;
  outline: none;
  border: 0;
  vertical-align: middle;
  text-decoration: none;
  background: transparent;
  padding: 0;
  font-size: inherit;
  font-family: inherit;
}
.button-back:disabled{
  opacity: .0;
}
.button-back.learn-more {
  width: 12rem;
  height: auto;
}

.button-back.learn-more .circle {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  position: relative;
  display: block;
  margin: 0;
  width: 3rem;
  height: 3rem;
  background: var(--rojo-gac);
  border-radius: 1.625rem;
}

.button-back.learn-more .circle .icon {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  background: #fff;
}

.button-back.learn-more .circle .icon.arrow {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  left: 0.625rem;
  width: 1.125rem;
  height: 0.125rem;
  background: none;
}

.button-back.learn-more .circle .icon.arrow::before {
  position: absolute;
  content: "";
  top: -0.29rem;
  right: 0.0625rem;
  width: 0.625rem;
  height: 0.625rem;
  border-top: 0.125rem solid #fff;
  border-right: 0.125rem solid #fff;
  transform: rotate(228deg);
}

.button-back.learn-more .button-back-text {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 0.75rem 0;
  margin: 0 0 0 1.85rem;
  color: var(--rojo-gac);
  font-weight: 700;
  line-height: 1.6;
  text-align: center;
  text-transform: uppercase;
}

.button-back:hover .circle {
  width: 100%;
}

.button-back:hover .circle .icon.arrow {
  background: #fff;
  transform: translate(200px, 0px);
}

.button-back:hover .button-back-text {
  color: #fff;
}
.btn-cupon {
  background: transparent;
  position: absolute;
  /* padding: 5px 15px; */
  display: flex;
  margin: 0px 15%;
  align-items: center;
  font-size: 17px;
  font-weight: 500;
  text-decoration: none;
  cursor: pointer;
  border: 1px solid var(--azul-gac);
  border-radius: 25px;
  outline: none;
  overflow: hidden;
  color: var(--azul-gac);
  transition: color 0.2s 0.1s ease-out;
  text-align: center;
  width: 70%;
  height: 47px;
  bottom: 0;
}
:root {
  --price-tag-background: var(--amarillo-gac);
  --price-tag2-background: var(--gris-gac);
  --price-tag-height: 2rem;
}

.price-tag {
  background: var(--price-tag-background);
  color: #fff;
  /* Center the price */
  align-items: center;
  display: flex;
  justify-content: center;
  /* Used to position the triangle */
  position: relative;
  /* Size */
  height: var(--price-tag-height);
  /* Spacing */
  padding: 0.25rem 0.5rem;
  border-radius: 0 5px 5px 0;
  width: 100%;
}

/* The triangle */
.price-tag::before {
  content: '';
  border-color: transparent var(--price-tag-background) transparent transparent;
  border-style: solid;
  border-width: calc(var(--price-tag-height) / 2) calc(var(--price-tag-height) / 2) calc(var(--price-tag-height) / 2) 0rem;
  /* Position */
  left: 0px;
  position: absolute;
  top: 0px;
  transform: translate(-100%, 0px);
}

/* The dot */
.price-tag::after {
  content: '';
  /* Make it like a cirle */
  background: #fff;
  border-radius: 9999rem;
  /* Position */
  left: 0;
  position: absolute;
  top: 50%;
  transform: translate(-0.5rem, -50%);
  /* Size */
  height: 0.5rem;
  width: 0.5rem;
}
/*  */
.price-tag2 {
  background: var(--price-tag2-background);
  color: #fff;
  /* Center the price */
  align-items: center;
  display: flex;
  justify-content: center;
  /* Used to position the triangle */
  position: relative;
  /* Size */
  height: var(--price-tag-height);
  /* Spacing */
  padding: 0.25rem 0.5rem;
  border-radius: 0 5px 5px 0;
  width: 100%;
}
/* The triangle */
.price-tag2::before {
  content: '';
  border-color: transparent var(--price-tag2-background) transparent transparent;
  border-style: solid;
  border-width: calc(var(--price-tag-height) / 2) calc(var(--price-tag-height) / 2) calc(var(--price-tag-height) / 2) 0rem;
  /* Position */
  left: 0px;
  position: absolute;
  top: 0px;
  transform: translate(-100%, 0px);
}

/* The dot */
.price-tag2::after {
  content: '';
  /* Make it like a cirle */
  background: #fff;
  border-radius: 9999rem;
  /* Position */
  left: 0;
  position: absolute;
  top: 50%;
  transform: translate(-0.5rem, -50%);
  /* Size */
  height: 0.5rem;
  width: 0.5rem;
}
.btn-cupon span {
  margin: 10px;
  display: flex;
  text-align: center;
  margin: auto;
}

.btn-cupon::before {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  content: '';
  border-radius: 50%;
  display: block;
  width: 20em;
  height: 20em;
  left: -5em;
  text-align: center;
  transition: box-shadow 0.4s ease-out;
  z-index: -1;
}

.btn-cupon:hover {
  color: #fff;
  border: 1px solid var(--fondo-defecto);
}

.btn-cupon:hover::before {
  box-shadow: inset 0 0 0 10em var(--azul-gac);
}

.title-alianza-plus{
  color: var(--azul-gac) ;
  font-weight: 700 ;
  position: relative;
  font-size: 3rem;
  text-shadow: 2px 2px 1px #00389369;
}
.alice-carousel__dots-item:not(.__custom).__active {
  background-color: var(--azul-gac) !important;
}
.containerCupon{
  width: 100%;
  /* height: 100vh;  */
  /* background: #f0fff3; */
  display: flex;
  align-items: center;
  justify-content: center;

}
.coupon-card{
  color: #fff;
  text-align: center;
  padding: 30px 80px;
  border-radius: 35px;
  box-shadow: 0 10px 10px 0 rgba(0,0,0,0.15);
  position: relative;
  width: 41%;
  height: 170px;
  margin-bottom: 20px;
  background-image: 
  linear-gradient( to bottom, rgb(232 199 117  / 100%), rgb(25 65 147/ 100%) ), 
  url("https://vip.grupoalianzacolombia.com/static/media/fondoOferta.d3a9794034b0ca39c5ac.svg");
}
.img-ofertas{
  height: 370px;
  width: 400px;
  display: flex;
  margin: 0 auto -52px auto;
}
.logoC{
  width: 80px;
  border-radius: 8px;
  margin-bottom: 20px;
}
.coupon-card h3{
  font-size: 28px;
  font-weight: 400;
  line-height: 40px;
}
.coupon-card p{
  font-size: 15px;
}
.coupon-row{
  display: flex;
  align-items: center;
  margin: 25px auto;
  width: fit-content;

}
#cpnCode{
  border: 1px dashed #fff;
  padding: 10px 20px;
  border-right: 0;

}
#cpnBtn{
  border: 1px solid #fff;
  background: #fff;
  padding: 10px 20px;
  color: #7158fe;
  cursor: pointer;
}
.circle1, .circle2{
  background: #f0fff3;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);

}
.circle1{
  left: -25px;
}
.circle2{
  right: -25px;
}

.cardOfertas {
  /* width: 190px; */
  /* height: 454px; */
  padding: .7em;
  position: relative;
  overflow: visible;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  background-color: hsla(0,0%,100%,.8) ;
  backdrop-filter: blur(7px) ;
  margin: 10px 0px;
  cursor: pointer;
}

 .cardOfertas-img {
  background-color: #ffcaa6;
  height: 40%;
  width: 100%;
  border-radius: .5rem;
  transition: .3s ease;
  background-image: url("https://vip.grupoalianzacolombia.com/alianzaplus/img/ofertas/13/portada.png");
 }
 
 .cardOfertas-info {
  padding-top: 10%;
 }
 
 svg {
  width: 20px;
  height: 20px;
 }
 
 .cardOfertas-footer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 10px;
  border-top: 1px solid #ddd;
 }
 
 /*Text*/
 .cardOfertas-text-title {
  font-weight: 900;
  font-size: 1.2em;
  line-height: 1.5;
 }
 
 .cardOfertas-text-body {
  font-size: .9em;
  padding-bottom: 10px;
 }
 
 /*Button*/
 .cardOfertas-button {
  border: 1px solid #252525;
  display: flex;
  padding: .3em;
  cursor: pointer;
  border-radius: 50px;
  transition: .3s ease-in-out;
 }
 
 /*Hover*/
 .cardOfertas-img:hover {
  transform: translateY(-25%);
  box-shadow: rgba(226, 196, 63, 0.25) 0px 13px 47px -5px, rgba(180, 71, 71, 0.3) 0px 8px 16px -8px;
 }
 
 .cardOfertas-button:hover {
  border: 1px solid #ffcaa6;
  background-color: #ffcaa6;
 }

 .default-btn-style-three {
  border: none;
  position: relative;
  display: inline-block;
  text-align: center;
  overflow: hidden;
  z-index: 1;
  color: #273152;
  background-color: transparent;
  transition: 0.5s;
  border-radius: 30px;
  border: 1px dashed #ebba26;
  margin-right: 2%;
  font-weight: 700;
  font-size: 16px;
  padding-left: 60px;
  padding-right: 35px;
  padding-top: 15px;
  padding-bottom: 15px;
}
.default-btn-style-three i {
  position: absolute;
  left: 35px;
  top: 14px;
}
.default-btn-style-three::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: #ebba26;
  z-index: -1;
  border-radius: 30px;
  margin: 4px;
  transition: 0.3s;
}
.default-btn-style-three:hover {
  color: #ffffff;
}
.default-btn-style-three:hover::before {
  margin: 0;
}
.default-btn-style-three:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
.default-btn-style-two {
  border: none;
  position: relative;
  display: inline-block;
  text-align: center;
  overflow: hidden;
  z-index: 1;
  color: #ffffff;
  background-color: transparent;
  transition: 0.5s;
  border-radius: 30px;
  border: 1px dashed #BF121B;
  font-weight: 700;
  font-size: 16px;
  padding-left: 60px;
  padding-right: 35px;
  padding-top: 15px;
  padding-bottom: 15px;
}

.default-btn-style-two::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: #BF121B;
  z-index: -1;
  border-radius: 30px;
  margin: 4px;
  transition: 0.3s;
}
.default-btn-style-two:hover {
  color: #ffffff;
}
.default-btn-style-two:hover::before {
  margin: 0;
}
.default-btn-style-two:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}


.site-btn{
  font-size: 14px;
  color: #ffffff;
  font-weight: 800;
  text-transform: uppercase;
  display: inline-block;
  padding: 13px 30px 12px;
  background: #111b5e;
  border: none;
  border-radius: 8px;
}
.cardCupon{
  border-radius: 15px;
  border: none;
  box-shadow: 3px 3px 9px #f5f6f8;
  max-height: max-content;
  margin: 1rem;
  animation: fadeIn .6s linear;
  min-height: 400px;
  transform: scale(0.99);
  transition: all .2s;
  padding: 1px;
}
@keyframes fadeIn {
  0%{
    opacity: 0;
    filter: blur(15px);
    transform: scale(.4);
  }
}
.modal{
  --bs-modal-footer-border-width: 0px;
}
.btn-outline-danger{
  padding: 15px 25px;
  border-radius: 15px;
  color: var(--rojo-gac);
  z-index: 1;
  position: relative;
  font-weight: 400;
  font-size: 17px;
  box-shadow: 4px 8px 19px -3px rgb(0 0 0 / 27%);
  transition: all 250ms;
  overflow: hidden;
}
.traslucido{
  background: hsl(217deg 40% 85% / 38%) !important;
  backdrop-filter: blur(7px) !important;
  border-radius: 20px;
} 

.loader {
  width: 48px;
  height: 48px;
  margin: auto;
  position: relative;
}

.loader:before {
  content: '';
  width: 48px;
  height: 5px;
  background: var(--amarillo-gac);
  position: absolute;
  top: 60px;
  left: 0;
  border-radius: 50%;
  animation: shadow324 0.5s linear infinite;
}

.loader:after {
  content: '';
  width: 100%;
  height: 100%;
  background: var(--amarillo-gac);
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 4px;
  animation: jump7456 0.5s linear infinite;
}

@keyframes jump7456 {
  15% {
    border-bottom-right-radius: 3px;
  }

  25% {
    transform: translateY(9px) rotate(22.5deg);
  }

  50% {
    transform: translateY(18px) scale(1, .9) rotate(45deg);
    border-bottom-right-radius: 40px;
  }

  75% {
    transform: translateY(9px) rotate(67.5deg);
  }

  100% {
    transform: translateY(0) rotate(90deg);
  }
}

@keyframes shadow324 {

  0%,
    100% {
    transform: scale(1, 1);
  }

  50% {
    transform: scale(1.2, 1);
  }
}

.cardCupon:hover .example2__title{
  background-color: #e30b23d5;
  color: white;
}

.cardCupon:hover{
  transform: scale(1.03);
  color: var(--azul-gac);
  padding: 0px;
}

.cardCupon img{
  border-radius: 15px;
  /* height: 90%; */
}
.alice-carousel__stage-item{
  /* margin: 10px !important; */
  color: var(--oscuro) !important;
}
.cardOferta{
  border-radius: 15px;
  border: none;
  box-shadow: 0px 0px 9px #e4e7ec;
  min-height: 290px;
  background-image: url("/public/promos/Textura.svg");
  animation: fadeIn .5s ease-in-out;
  margin: 0.6rem;
}
.br-20{ 
  border-radius: 1rem;
  max-width: 100%;
}

.contenedor-scroll{
  background: url("https://placehold.jp/800x300.png");
  background-attachment:  fixed;
  border-radius: 15px;
  height: 280px;
  /* width: 100%; */
  /* display: flex; */
  /* align-items: center; */
  /* justify-content: center; */
  color: white;
}
.sticky {
  position: fixed;
  /* top: 20; */
  width: 100%;
}

.contenedor-ofertas{
  overflow: auto; /*Firefox*/
  overflow: overlay;
  height: 100vh;
  width: 110%;
}
.cardOferta:hover .example2__title{
  background-color: var(--amarillo-gac) !important;
  color: var(--oscuro) !important;
 }

.cardOferta img{
  border-radius: 15px;
}
.categories__item{
  height: 270px;
  position: relative;
  border-radius: 8px;
} 
.set-bg{
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-position: top center !important;
}
.color-nav{
  background-color: hsla(0,0%,100%,.8) !important;
  backdrop-filter: blur(7px) !important;
}
.bodyCupon{
  border-radius: 0 0 20px 20px;
  background-color: hsla(0,0%,100%,.8);
  backdrop-filter: blur(7px);
  /* border: 1px solid hsla(0,21%,90%,.9);
  border-top: transparent; */
  /* box-shadow: 0px 0px 29px #b9bdc3; */
  position: absolute;
  margin-top: 200px;
  margin-bottom: 100px;
  font-weight: 400;
  font-size: 16px;
  width: 100%;
  height: 140px;
  padding: 10px;
  z-index: 1;
}
.bodyCupon:hover{
  background-color: hsla(0,0%,100%,.8);
  backdrop-filter: blur(9px);
}

.front, .back, .container3 {
  width: 285px;
  height: 400px;
}
.front, .back {
  backface-visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
}
.front {
  z-index: 2;
  transition: all 0.3s linear;
}
.back {
  transition: all 0.3s linear;
  transform: rotateY(180deg);
}
.container3 {
  position: relative;
  transform-style: preserve-3d;
  perspective: 1000px;
}
.container3:hover .front {
  transform: rotateY(180deg);
}
.container3:hover .back {
  transform: rotateY(360deg);
}
.w-100 {
  /* width: 94%!important; */
  border-radius: 20px;
  /* border: 2px dashed #c1c1c1; */
  /* padding: 6px; */
  /* margin: auto; */
}

.btn-oferta:disabled{
  cursor: not-allowed;
}
.btn-oferta {
  padding: 15px 25px;
  border: unset;
  border-radius: 15px;
  color: #212121;
  z-index: 1;
  background: var(--amarillo-gac);
  position: relative;
  font-weight: 1000;
  font-size: 17px;
  -webkit-box-shadow: 4px 8px 19px -3px rgba(0,0,0,0.27);
  box-shadow: 4px 8px 19px -3px rgba(0,0,0,0.27);
  transition: all 250ms;
  overflow: hidden;
}
 
.btn-oferta::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 0;
  border-radius: 15px;
  background-color: #212121;
  z-index: -1;
  -webkit-box-shadow: 4px 8px 19px -3px rgba(0,0,0,0.27);
  box-shadow: 4px 8px 19px -3px rgba(0,0,0,0.27);
  transition: all 250ms
}
 
.btn-oferta:hover {
  color: #e8e8e8;
}
 
.btn-oferta:hover::before {
  width: 100%;
}
 .title-sections{
  color: #252525;
  font-weight: 700;
  margin-bottom: 16px;
}
.start_component {
  margin: 1px;
  height: 15px;
  width: 15px;
}
.rating .start_component:nth-child(2n){
  height: 18px;
  width: 18px;
}
.rating .start_component:nth-child(3){
  height: 21px;
  width: 21px;
}
.carousel-control-prev,
.carousel-control-next {
  width: 15%;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 3.5rem;
  background-color: var(--rojo-gac);
  border: 15px solid var(--rojo-gac);
}

/* this is a recreation of twitter search in css */
.form {
  --input-text-color: #fff;
  --input-bg-color: #252525;
  --focus-input-bg-color: transparent;
  --text-color: #0581fd;
  --active-color: var(--oscuro);
  --width-of-input: 100%;
  --inline-padding-of-input: 1.2em;
  --gap: 0.9rem;
}
/* form style */
.form {
  font-size: 0.9rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 0.5rem;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: var(--width-of-input);
  position: relative;
  isolation: isolate;
}
/* a fancy bg for showing background and border when focus. */
.fancy-bg {
  position: absolute;
  width: 100%;
  inset: 0;
  background: var(--input-bg-color);
  border-radius: 30px;
  height: 100%;
  z-index: -1;
  pointer-events: none;
  -webkit-box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
          box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}
/* label styling */
label {
  width: 100%;
  padding: 0.8em;
  height: 40px;
  padding-inline: var(--inline-padding-of-input);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.search,.close-btn {
  position: absolute;
}
/* styling search-icon */
.search {
  fill: var(--text-color);
  left: var(--inline-padding-of-input);
}
/* svg -- size */
svg {
  width: 17px;
  display: block;
}
/* styling of close button */
.close-btn {
  border: none;
  right: var(--inline-padding-of-input);
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  color: #fff;
  padding: 0.1em;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: var(--active-color);
  opacity: 0;
  visibility: hidden;
}
/* styling of input */
.input {
  color: var(--input-text-color);
  width: 100%;
  margin-inline: min(2em,calc(var(--inline-padding-of-input) + var(--gap)));
  background: none;
  border: none;
}

.input:focus {
  outline: none;
  color: var(--oscuro);
}

.input::-webkit-input-placeholder {
  color: var(--fondo-defecto)
}

.input::-moz-placeholder {
  color: var(--fondo-defecto)
}

.input:-ms-input-placeholder {
  color: var(--fondo-defecto)
}

.input::-ms-input-placeholder {
  color: var(--fondo-defecto)
}

.input::placeholder {
  color: var(--fondo-defecto)
}
/* input background change in focus */
.input:focus ~ .fancy-bg {
  border: 1px solid var(--active-color);
  background: var(--fondo-defecto);
  border: 1px solid var(--text-color);
}
/* search icon color change in focus */
.input:focus ~ .search {
  fill: var(--active-color);
}
/* showing close button when typing */
.input:valid ~ .close-btn {
  opacity: 1;
  visibility: visible;
}
/* this is for the default background in input,when selecting autofill options -- you can remove this code if you do not want to override the browser style.  */
.input:-webkit-autofill,
.input:-webkit-autofill:hover,
.input:-webkit-autofill:focus,
.input:-webkit-autofill:active {
  -webkit-transition: "color 9999s ease-out, background-color 9999s ease-out";
  -webkit-transition-delay: 9999s;
}

.alert-success{
  animation:shake-left-right 1s linear both;
  border-radius: 15px;
  padding: 25px 35px 20px 25px;
  box-shadow: 0 6px 20px -5px rgba(0, 0, 0, 0.2);
  overflow: hidden;   
  transition: all 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.35);
  border: none;
}
@keyframes shake-left-right{
  0%{ transform: translateX(calc(100% + -30px))}
  30%,40%,60%{transform:rotate(-3deg)}
  30%,50%,70%{transform:rotate(3deg)}
  80%{transform:rotate(-6deg)}
  90%{transform:rotate(6deg)}
}
/* Responsive */
@media only screen and (max-width: 650px){
  .btn-WhatsApp{
    left: 75%;
  }
  .btn-WhatsApp img{
    width: 90%;
    height: 90%;
  }
  .msg-whatsApp{
    font-size: 10px;
  }
  .coupon-card{ 
    width: 100%;
    padding: 24px 60px;
  }
  .logo{
    width: 193px;
  }
  .class-logo-white{
    width: 120px;
  }
  footer p a {
    font-size: 10px;
    line-height: 2px;
  }
  .gac{
    height: 110px;
    width: 110px;
  }
  h6{
    font-size: 10px;
  }
  .menu-lateral{
    display: none;
  }
  .banner{
    padding: 11px;
  }
  .card-user{
    margin: 10px 0px;
    /* height: 300px; */
    padding: 17px;
    border: 1px solid var(--amarillo-gac);
    border-top: 4px solid var(--amarillo-gac);
  }
  .img-card{
    display: flex;
    margin: auto;
    border: 2px dashed var(--azul-gac);
    padding: 5px;
  }
  .portada{
    width: 100px;
  }
  .img-avatar{
    left: 46px;
  }
  .cardHotel{
    width: auto;
    height: auto;
    margin: 12px 0px;
  }
  .btn-reservar{
    background-color: var(--amarillo-gac);
    color: var(--oscuro);
    font-weight: 600;
  }
  .btn-reservar:hover{
    background-color: var(--azul-gac);
  }
  .card-afiliado{
    width: 100%;
  }
  .card-afiliado .desc{
    padding: 0rem 0rem;
    font-size: 11px;
  }
  h4 { font-size: 18px};
  .w-100{
    width: 100%!important;
    border-radius: 20px;
    border: 2px dashed #c1c1c1;
    padding: 6px;
    margin: auto;
  } 
  .card-info{
    height: max-content;
    margin-bottom: 20px;
  }
  .Mycard{
    width: auto;
  }
}
.btn-outline-danger{
  font-size: 14px;
} 

::-webkit-scrollbar {
  width: 7px;
  height: 10px;
}
::-webkit-scrollbar-track {
  background-color:  var(--fondo-defecto); 
  border-radius: 10px;
}
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: var(--azul-gac); 
}

/* Responsive table */

@media only screen and (min-width: 760px) and  (max-width: 768px)
{
  .img-card{
    display: flex;
    margin: auto;
    border: 2px dashed var(--azul-gac);
    padding: 5px;
  }
  .card-user{
    margin: 10px 0px;
    height: 170px;
    padding: 17px;
    border-top: 4px solid var(--amarillo-gac);
  }
  .class-logo-white{ width: 167px;}
  .navbar-toggler{ display: none;}
  .navbar-collapse{ display: none;}
  .collapse{ display: none;}
  /* .show{ display: none;} */
  a{ color: rgb(15, 15, 15) !important;}
  .btn-menu{padding: 10px 4px;}
  .btn-menu-active{padding: 10px 4px;}
  .btn-WhatsApp{
    left: 84%;
    top: 70%;
  }
}

@media only screen and (min-width: 769px){
  .class-logo-white{ width: 167px;}
  .navbar-toggler{ display: none;}
  .navbar-collapse{ display: none;}
  .collapse{ display: none;}
  .navbar ul li a{
    display: none;
  }
  /* .show{ display: none;} */
  /* a{ color: white !important;} */
} 
